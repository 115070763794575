import React, { useEffect, useState } from "react";

import { fetchStore } from "@/components/Store/components/fetchStores";
import { Tree } from "@/components/Store/components/trees";

interface StoreFinderContext {
  store: Tree | null;
  refetch: () => void;
  setCurrentStore: (storeId: null | string) => void;
  getCurrentStore: () => string | null;
}

const StoreFinderContext = React.createContext<StoreFinderContext | null>(null);

export const useStoreFinder = () => {
  const context = React.useContext(StoreFinderContext);
  if (context === null) {
    throw new Error("useStore must be used within a StoreProvider");
  }
  return context;
};

interface StoreFinderProps {
  children: React.ReactNode;
}

export const StoreFinderProvider = ({ children }: StoreFinderProps) => {
  const [storeId, setStoreId] = useState<string | null>(null);
  const [store, setStore] = useState<Tree | null>(null);

  const getCurrentStore = () => {
    const _storeId = localStorage.getItem("storeId");

    if (_storeId?.length) {
      return _storeId;
    }

    return storeId;
  };

  useEffect(() => {
    if (!getCurrentStore()) {
      refetch();
    }
  }, [storeId]);

  const setCurrentStore = (_storeId: string | null) => {
    localStorage.setItem("storeId", _storeId ?? "");
    setStoreId(_storeId);
  };

  const refetch = () => {
    const _currentStore = getCurrentStore();

    if (_currentStore) {
      fetchStore(_currentStore).then(({ data }) => {
        const _store = data?.at(0)?.attributes;

        if (_store) {
          setStore(_store);
          localStorage.setItem("storeId", _store.storeId);
        }
      });
    } else {
      setStore(null);
    }
  };

  return (
    <StoreFinderContext.Provider
      value={{
        store,
        refetch,
        setCurrentStore,
        getCurrentStore,
      }}
    >
      {children}
    </StoreFinderContext.Provider>
  );
};
