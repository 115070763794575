"use client";

import { usePathname, useSearchParams } from "next/navigation";
import NProgress from "nprogress";
import { Suspense, useContext, useEffect, useRef } from "react";

import { LoadingContext } from "@/lib/contexts/loading-context";

export function LoadingProgressBar() {
  const context = useContext(LoadingContext);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const set = useRef(new Set<unknown>());
  const timeout = useRef<number | NodeJS.Timeout | null>(null);

  useEffect(() => {
    NProgress.configure({
      parent: "#loading-progress",
      template: `
        <div id="nprogress" class="pointer-events-none">
          <div class="bg-primary fixed z-[1031] top-0 left-0 w-full h-0.5 shadow-[0_0_10px_var(--color-primary),0_0_3px_var(--color-primary)]" role="bar">
            <div class="block absolute right-0 w[100px] h-full opacity-100 rotate-3 translate-y-[-4px]"></div>
          </div>
          <div class="block fixed z-[1031] top-4 right-4" role="spinner">
            <div class="w-4 h-4 border-2 rounded-full border-t-primary border-l-primary animate-spin"></div>
          </div>
        </div>
      `,
    });

    const handleAnchorClick = (event: MouseEvent) => {
      if (event.ctrlKey || event.shiftKey) {
        // Ignorieren, wenn in einem neuen Tab/Fenster geöffnet wird
        return;
      }
      const targetUrl = (event.currentTarget as HTMLAnchorElement).href;
      const currentUrl = window.location.href;
      if (targetUrl !== currentUrl) {
        context.emit("startLoading", "next/navigation");
      }
    };

    const handleMutation: MutationCallback = () => {
      const anchorElements = document.querySelectorAll<HTMLAnchorElement>("a[href]");
      anchorElements.forEach((anchor) => anchor.addEventListener("click", handleAnchorClick));
    };

    const mutationObserver = new MutationObserver(handleMutation);
    mutationObserver.observe(document, { childList: true, subtree: true });
    return () => {
      document.getElementById("nprogress")?.remove();
      document.querySelectorAll<HTMLAnchorElement>("a[href]").forEach((anchor) => anchor.removeEventListener("click", handleAnchorClick));
    };
  }, [context]);

  useEffect(() => {
    context.emit("stopLoading", "next/navigation", 0.5);
  }, [context, pathname, searchParams]);

  useEffect(() => {
    const currentSet = set.current;
    const startLoading = (key: unknown) => {
      if (timeout.current) {
        clearTimeout(timeout.current as number);
        timeout.current = null;
      }
      currentSet.add(key);
      NProgress.start();
    };
    const stopLoading = (key: unknown, progress?: number) => {
      currentSet.delete(key);
      NProgress.inc(progress);
      if (currentSet.size === 0) {
        // Verzögern, falls ein anderer Ladevorgang kurz danach startet
        timeout.current = setTimeout(() => {
          NProgress.done();
        }, 50);
      }
    };

    context.on("startLoading", startLoading);
    context.on("stopLoading", stopLoading);
    return () => {
      context.off("startLoading", startLoading);
      context.off("stopLoading", stopLoading);
      currentSet.clear();
    };
  }, [context]);

  return <div id="loading-progress"></div>;
}

export function LoadingProgress() {
  return (
    <Suspense>
      <LoadingProgressBar />
    </Suspense>
  );
}
