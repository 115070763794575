"use client";

import type { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import mitt, { MittEmitter } from "next/dist/shared/lib/mitt";
import { useRouter } from "next/navigation";
import React, { Dispatch, SetStateAction, useCallback, useContext } from "react";

const routerEvents = ["startLoading", "stopLoading"] as const;
export type Events = (typeof routerEvents)[number];
type ContextType = MittEmitter<Events>;
const mittValue = mitt();
export const LoadingContext = React.createContext<ContextType>(mittValue);

/**
 * Statt [loading, setLoading] = useState(false) verwenden, um den Ladezustand auch im pace/nprogress darzustellen
 *
 * @param key Ein eindeutiger Schlüssel für den Ladevorgang (z.B. "cart")
 * @param initialState
 * @param progress Geschätzter Fortschritt zwischen 0 und 1 den der Abschluss dieses Ladevorgangs darstellt
 */
export function useLoading(key: string, initialState = false, progress?: number): [boolean, Dispatch<SetStateAction<boolean>>] {
  const context = useContext(LoadingContext);
  const [loading, _setLoading] = React.useState<boolean>(initialState);
  const setLoading = useCallback(
    (value: SetStateAction<boolean>) => {
      _setLoading((prevValue) => {
        const newValue = typeof value === "function" ? value(prevValue) : value;
        if (prevValue && !newValue) {
          context.emit("stopLoading", key, progress);
        } else if (!prevValue && newValue) {
          context.emit("startLoading", key);
        }
        return newValue;
      });
    },
    [_setLoading, context, key, progress],
  );
  if (loading) {
    context.emit("startLoading", key);
  }
  return [loading, setLoading];
}

/**
 * Ladezustand von einem isLoading aus anderer Quelle auch im pace/nprogress darzustellen
 * Beispiel:
 * ```ts
 * const [cart, isLoading] = useCart();
 * useLoadingStatus(isLoading, 'cart');
 * ```
 */
export function useLoadingStatus(status: boolean, key: string, progress?: number) {
  useLoading(key, false, progress)[1](status);
}

/**
 * Um einen Async-Function wrappen, um den Ladezustand auch im pace/nprogress darzustellen.
 *
 * Beispiel:
 * ```ts
 * const progress = usePromiseProgress();
 * const result = await progress(fetch('/api/data'), 0.1);
 * ```
 */
export function usePromiseProgress() {
  const context = useContext(LoadingContext);
  return useCallback(
    async <T,>(promise: Promise<T>, progress?: number): Promise<T> => {
      context.emit("startLoading", promise);
      try {
        return await promise;
      } finally {
        context.emit("stopLoading", promise, progress);
      }
    },
    [context],
  );
}

/**
 * Statt useRouter() verwenden, um den Ladezustand durch push() auch im pace/nprogress darzustellen
 */
export function useLoadingRouter(): AppRouterInstance {
  const context = useContext(LoadingContext);
  const { push: originalPush, ...router } = useRouter();

  const push: typeof originalPush = (href, ...args) => {
    const targetUrl = href;
    const currentUrl = window.location.pathname; // Eventuell durch usePathname() ersetzen, aber keine Ahnung was die Sideffects wären
    if (targetUrl !== currentUrl) {
      context.emit("startLoading", "next/navigation", ...args);
    }
    return originalPush(href, ...args);
  };

  return { push, ...router };
}

export function LoadingProvider({ children }: React.PropsWithChildren) {
  return <LoadingContext.Provider value={mittValue}>{children}</LoadingContext.Provider>;
}
