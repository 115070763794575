import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { useMeCustomer } from "medusa-react";
import React, { useEffect, useState } from "react";

import { medusaClient } from "@/lib/config";

interface WishlistContext {
  wishlist: WishlistInterface | null;
  refetch: () => void;
  addProduct: (productId: string) => void;
  removeProduct: (productId: string) => void;
  isProductInWishlist: (productId: string) => boolean;
}

const WishlistContext = React.createContext<WishlistContext | null>(null);

export const useWishlist = () => {
  const context = React.useContext(WishlistContext);
  if (context === null) {
    throw new Error("useStore must be used within a StoreProvider");
  }
  return context;
};

interface WishlistProps {
  children: React.ReactNode;
}

export interface WishlistInterface {
  customer_id: string | null;
  id: string;
  items: WishlistItem[];
  object: string;
  region_id: string;
}

interface WishlistItem {
  product: PricedProduct;
}

export const WishlistProvider = ({ children }: WishlistProps) => {
  const { customer } = useMeCustomer();
  const [regionId, setRegionId] = useState<string | null>(null);
  const [wishlist, setWishlist] = useState<WishlistInterface | null>(null);

  useEffect(() => {
    if (!regionId) {
      fetchRegion();
    }
  }, []);

  useEffect(() => {
    if (regionId) {
      if (!localStorage.getItem("wishlist")) {
        customer ? createWishlist(regionId) : createLocalWishlist(regionId);
      }
    }
  }, [regionId]);

  const createLocalWishlist = (regionId: string) => {
    const _wishlist: WishlistInterface = {
      id: "localWishlist",
      customer_id: null,
      items: [],
      object: "wishlist",
      region_id: regionId as string,
    };

    setWishlist(_wishlist);
    localStorage.setItem("wishlist", JSON.stringify(_wishlist));
  };

  const createWishlist = (regionId: string) => {
    const payload = { region_id: regionId };

    medusaClient.client.request("POST", "/store/wishlist", payload).then((res) => {
      setWishlist(res);
      localStorage.setItem("wishlist", res.id);
    });
  };

  const getWishlist = (id: string) => {
    const payload = { id: id };

    medusaClient.client.request("GET", "/store/wishlist", payload).then((res) => {
      setWishlist(res);

      if (res.id !== id) {
        localStorage.setItem("wishlist", res.id);
      }
    });
  };

  const refetch = () => {
    const wishlistId = localStorage.getItem("wishlist");

    if (customer) {
      if (wishlistId) {
        getWishlist(wishlistId);
      }
    } else {
      !wishlistId ? createLocalWishlist(regionId ?? "") : refetchLocalWishlist();
    }
  };

  const refetchLocalWishlist = () => {
    const _wishlist = localStorage.getItem("wishlist");
    _wishlist && setWishlist(JSON.parse(_wishlist));
  };

  const fetchRegion = () => {
    medusaClient.client.request("GET", "/store/regions").then(({ regions }) => {
      setRegionId(regions.at(0)?.id as string);
    });
  };

  const isProductInWishlist = (productId: string) => {
    if (wishlist?.items.length) {
      const findProduct = wishlist.items.find((item) => item.product.id === productId);

      if (findProduct) {
        return true;
      }
    }

    return false;
  };

  const prepareLocalProduct = (product: PricedProduct) => {
    return {
      product: product,
    };
  };

  const addProduct = (productId: string) => {
    const id = localStorage.getItem("wishlist");

    if (!id) {
      throw new Error("wishlist not found!");
    }

    if (!customer) {
      medusaClient.products.retrieve(productId).then(({ product }) => {
        const _wishlist = JSON.parse(id);
        _wishlist.items.push(prepareLocalProduct(product));

        localStorage.setItem("wishlist", JSON.stringify(_wishlist));
        setWishlist(_wishlist);
      });
      return;
    }

    const payload = {
      id: id,
      product_id: productId,
    };

    medusaClient.client.request("POST", `/store/wishlistItem`, payload).then((res) => {
      setWishlist(res);
    });
  };

  const removeProduct = (productId: string) => {
    const id = localStorage.getItem("wishlist");

    if (!id) {
      throw new Error("wishlist not found!");
    }

    if (!customer) {
      const _wishlist = JSON.parse(id);
      const product = _wishlist?.items?.find((item: WishlistItem) => item?.product?.id === productId);

      _wishlist.items.splice(_wishlist.items.indexOf(product), 1);

      localStorage.setItem("wishlist", JSON.stringify(_wishlist));
      setWishlist(_wishlist);
      return;
    }

    const payload = {
      id: id,
      product_id: productId,
    };

    medusaClient.client.request("DELETE", `/store/wishlistItem`, payload).then((res) => {
      setWishlist(res);
    });
  };

  return (
    <WishlistContext.Provider
      value={{
        wishlist,
        refetch,
        addProduct,
        removeProduct,
        isProductInWishlist,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};
