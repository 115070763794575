"use client";

import { CartProvider, MedusaProvider } from "medusa-react";
import { ReactNode } from "react";

import { MEDUSA_BACKEND_URL, queryClient } from "@/lib/config";
import { FlyoutProvider } from "@/lib/contexts/flyout-context";
import { LayoutProvider } from "@/lib/contexts/layout-context";
import { LoadingProvider } from "@/lib/contexts/loading-context";
import { SideModalProvider } from "@/lib/contexts/sidemodal-context";
import { StoreProvider } from "@/lib/contexts/store-context";
import { StoreFinderProvider } from "@/lib/contexts/store-finder-context";
import { WishlistProvider } from "@/lib/contexts/wishlist-context";

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <MedusaProvider
      baseUrl={MEDUSA_BACKEND_URL}
      queryClientProviderProps={{
        client: queryClient,
      }}
    >
      <LoadingProvider>
        <CartProvider>
          <StoreProvider>
            <WishlistProvider>
              <LayoutProvider>
                <SideModalProvider>
                  <FlyoutProvider>
                    <StoreFinderProvider>{children}</StoreFinderProvider>
                  </FlyoutProvider>
                </SideModalProvider>
              </LayoutProvider>
            </WishlistProvider>
          </StoreProvider>
        </CartProvider>
      </LoadingProvider>
    </MedusaProvider>
  );
}
