import { fetchStrapi } from "@/data/strapi-fetch";

export const fetchStores = async () => {
  const pagination = { pageSize: 300 };
  return await fetchStrapi("/stores", {}, false, {}, pagination);
};

export const fetchStore = async (id: string) => {
  const filters = { storeId: { $eq: id } };
  return await fetchStrapi("/stores", filters, false);
};
